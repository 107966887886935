import React, { useEffect } from "react";
// import "./App.css";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  Routes,
  Navigate,
} from "react-router-dom";
import RedemptionForm from "./components/RedemptionForm";
import UserformSurvey from "./components/UserformSurvey";
import UserForm from "./components/UserForm";
import Thankyou from "./components/Thankyou";
// import Index from "./components/Index";
import NoInternet from "./components/NoInternet";
import RegisterForm from "./components/RegisterForm";
import NotFound from "./components/NotFound";
import TestOurTech_thankyou from "./components/TestOurTech_thankyou";
import CertDetails from "./components/CertDetails";

function App() {
  useEffect(() => {
    localStorage.setItem("isVoucher", false);
  }, []);
  if (process.env.REACT_APP_STAGE === "PROD")
    console.log = function no_console() {};
  return (
    //FIXME UserformSurvey -> survey-form, UserForm -> user-form, Thankyou -> redemption-thankyou, TestOurTech_thankyou -> registration-thankyou
    <NoInternet>
      <Routes>
        {/* <Route path="/" element={<RedemptionForm />} />
      <Route path="/survey-form" element={<UserformSurvey />} />
      <Route path="/user-form" element={<UserForm />} />
      <Route path="/redemption-thankyou" element={<Thankyou />} />
      <Route path="/certificate-details" element={<CertDetails />} /> */}
        {/* <Route path="/register/" element={<Index />} /> */}
        {/* <Route path="/" element={<RegisterForm />} />
      <Route
        path="/register/registration-thankyou"
        element={<TestOurTech_thankyou />}
      /> */}
        {/* <Route path="*" element={<Navigate to="/" />} />
         */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </NoInternet>
  );
}

export default App;
