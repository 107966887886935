import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

export default function NotFound() {
  // const locat = useLocation();

  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/");
  // }, [locat.pathname]);

  return (
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1>404</h1>
        </div>
        <h2>We are sorry, Page not found!</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.
        </p>
      </div>
    </div>
  );
  // <div className="showPage"></div>
}
